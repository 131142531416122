@import url('styles.css');

.layout.template_externo>.ant-layout-content {
	min-height: auto;
	height: auto;
    display: block;
    background-color: white;
}

.layout.template_externo.ant-layout {
    width: 100vw;
    height: 100vh;
    background-color: white;
    position: absolute;
    left: 0;
    top: 0;
}

.header_style_externo {
    width: 100%;
    padding: 0;
	background-color: white;
	z-index: 100;
    padding-left: 6em;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
    height: 280px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header_style_externo>div#enlaces_login {
    position: relative;
    text-align: right;
    line-height: 1;
    top: calc(50% - 1.5em);
    padding: 0 40px;
    font-size: 1.3em;
    flex: 1 0;
}

.header_style_externo>div#enlaces_login>a {
    color: var(--negro_SUPyCAP);
}

.header_style_externo>div#enlaces_login>a>i {
    color: var(--gris-ine-inactivo);
}

.header_style_externo>div>a {
    color: black;
    transition: ease color 0.5s;
}

.header_style_externo>div#enlaces_login>a:hover {
    color: var(--rosa-ine);
}

.header_style_externo>div#enlaces_login>a:hover>i {
    color: var(--negro_SUPyCAP);
    transition: ease color 0.5s;
}

.header_style_externo>div>hr {
    border: none;
    border-left: 2px solid black;
    height: 0.8em;
    width: 0;
    display: inline-block;
    margin: 0 10px;
}

#banda_titulo {
    max-width: 334px;
    min-width: 250px;
    width: 29%;
    height: auto;
}

#banda_titulo>img {
    width: 100%;
}

.ant-layout-footer.footer_style_externo {
	background-color: var(--gris-ine-inactivo);
	width: 100%;
	position: relative;
	height: 10vh;
	z-index: 100;
	padding: 20px 3em 24px;
}

.template_externo>.ant-layout-content>div[aria-busy] {
    height: 100%;
}