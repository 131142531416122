@import url('styles.css');

.ant-select-selection--single:focus, 
.ant-select-selection--single:active {
    border-color: var(--gris-ine);
    -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.ant-select-selection--single:hover {
    border-color: var(--gris-ine);
}

.ant-select-open .ant-select-selection {
    border-color: var(--gris-ine-inactivo);
}

.ant-select-open .ant-select-selection:hover,
.ant-select-open .ant-select-selection {
    -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: var(--rosa-ine-hover);
}

.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: var(--rosa-ine-alph);
}

.ant-select-focused .ant-select-selection, .ant-select-selection:focus, .ant-select-selection:active {
	border-color: var(--gris-ine);
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
}

.ant-select {
	width: 100%;
}

.select-container {
	height: auto;
	text-align: left;
	display: flex;
	flex-direction: column-reverse;
}

.ant-select-dropdown-menu-item {
	white-space: initial;
}