@import url('styles.css');

.progreso_registro {
    background-color: var(--gris-ine-fondo);
    height: 4.5px;
}

.progreso {
    transition: width 0.7s ease;
    display: block;
    background-color: rgb(64, 154, 161);
    height: 100%;
}