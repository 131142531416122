@import url('styles.css');

.flecha_wizard {
    transition: color 0.4s;
    color: var(--gris-ine);
    position: relative;
    font-size: 2.5em;
}

.flecha_wizard>.ant-btn {
    font-size: 0.4em;
}

.flecha_wizard:first-child {
    margin-left: 40px;
}

.flecha_wizard>span {
    font-size: 0.4em;
    position: relative;
    user-select: none;
    vertical-align: top;
}

.flecha_wizard_active {
    transition: color 0.4s;
    color: var(--negro_SUPyCAP);
}

.flecha_wizard_active:hover {
    transition: color 0.4s;
    color: var(--rosa-ine);
    cursor: pointer;
}

.flecha_wizard_left, .flecha_wizard_right {
    display: flex;
    align-items: center;
}

div.contenido_wizard {
    padding:  0 2em 0;
    min-height: 100%;
}

div.contenido_wizard~div {
    padding: 0 1em;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}

